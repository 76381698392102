import React            from 'react'
import Helmet           from 'react-helmet'
import { Link }         from 'gatsby'
import Emoji            from 'a11y-react-emoji'

import config           from '../../../data/site-config'

import Layout           from '../../components/layout'
import MainWrapper      from '../../components/MainWrapper/MainWrapper'
import PageHero         from '../../components/PageHero'
import PortfolioCard    from '../../components/Portfolio/PortfolioCard'
import ClientList       from '../../components/ClientList'

const PortfolioPage = () => (
  <Layout className="bg-near-white">

    <Helmet
      title={`💼 Design Portfolio • Creative Web, Brand, UX Design • Spacetime`}
      meta={[
        { name: 'description', content: 'Our web design portfolio overflows with creative, goal oriented clients successes. How can we help you grow and develop your business?' }
      ]}
      link={[
        { rel: 'canonical', href: `${config.siteUrl}/portfolio/` }
      ]}
    />

    <PageHero>
      <h1 className="t-headline">A good looking design portfolio isn&rsquo;t good enough&mdash;<span className="o-60">without results</span></h1>
      <h2 className="f5 fw4 lh-copy o-80">We believe a design portfolio can speak for itself, <em>but shouldn’t</em>.</h2>
    </PageHero>

    <MainWrapper>

      <div className="bg-near-white pv6 tc">
        <h3 className="t-subheadline lh-copy center mw7">We pour our hearts and souls into <em>every</em> project for <em>every</em> client. Big or small, highbrow or populist we treat each project like our own.</h3>
        <p><span className="o-50">We don’t always build billion dollar ventures, but we sure do try.</span> <Link to="/team/">Meet the team →</Link></p>
      </div>

      <div className="row w-75-l pv5 pv6-l flex-l">
        <div className="w-50-l">

          <Link
            to="/portfolio/dude-perfect/"
            style={{backgroundColor: `#00FFDE`}}
            className="PortfolioCard"
          >
            <img className="PortfolioCard__logo" src="/client-logos/dude-perfect-icon.svg" alt="Dude Perfect logo" />
            <h3 className="t-subheadline">
              Dude Perfect&mdash;<span className="o-40">YouTube influencers specializing in the trick&nbsp;shot</span>
            </h3>
            <span>
              Read the Dude Perfect case study<br />
              <span className="arrow-decorator" />
            </span>

            <img className="PortfolioCard__bg" src="/portfolio/dude-perfect/dude-perfect-card-bg.jpg" alt="Dude Perfect guys jumping in the air" />
          </Link>

          <Link
            to="/portfolio/keyzie/"
            style={{backgroundColor: `#F78657`}}
            className="PortfolioCard"
          >
            <img className="PortfolioCard__logo" src="/client-logos/keyzie-logo.svg" alt="Keyzie logo" />
            <h3 className="t-subheadline">
              Keyzie&mdash;<span className="o-40">the future of real estate is&nbsp;personal</span>
            </h3>
            <span>
              Read the Keyzie case study<br />
              <span className="arrow-decorator" />
            </span>

            <img className="PortfolioCard__bg" src="/portfolio/keyzie/keyzie-card-bg.jpg" alt="Front door to an apartment video" />
          </Link>

        </div>

        <div className="w-50-l mt5-l">

          <Link
            to="/portfolio/studiohop/"
            style={{backgroundColor: `#00C2F2`}}
            className="PortfolioCard"
          >
            <img className="PortfolioCard__logo" src="/client-logos/studiohop-icon.svg" alt="StudioHop Fitness logo" />
            <h3 className="t-subheadline">
              StudioHop&mdash;<span className="o-40">fitness classes whenever and wherever you&nbsp;like</span>
            </h3>
            <span>
              Read the StudioHop case study<br />
              <span className="arrow-decorator" />
            </span>

            <img className="PortfolioCard__bg" src="/portfolio/studiohop/studiohop-card-bg.jpg" alt="StudioHop woman in a boxing class" />
          </Link>

          <Link
            to="/portfolio/steadkey/"
            style={{backgroundColor: `#f64a53`}}
            className="PortfolioCard"
          >
            <img className="PortfolioCard__logo" src="/client-logos/steadkey-logo.svg" alt="Steadkey logo" />
            <h3 className="t-subheadline">
              Steadkey&mdash;<span className="o-40">deliver sanity and clarity to the mortgage&nbsp;process</span>
            </h3>
            <span>
              Read the Steadkey case study<br />
              <span className="arrow-decorator" />
            </span>

            <img className="PortfolioCard__bg" src="/portfolio/steadkey/steadkey-card-bg.jpg" alt="A modern style home" />
          </Link>
        </div>
      </div>

      <h5 className="tc f2">Fantastic. <span className="o-40">Superb. Inconceivable.</span><Emoji symbol="⭐️" label="eyes" className="f3 ml2"/></h5>

      <div className="row w-75-l pv5 pv6-l flex-l">
        <div className="w-50-l">

          <Link
            to="/portfolio/bell/"
            style={{backgroundColor: `#000`}}
            className="PortfolioCard"
          >
            <img className="PortfolioCard__logo" src="/client-logos/bell-logo.svg" alt="Bell Helicopter logo" />
            <h3 className="t-subheadline white">
              Bell&mdash;<span className="o-40">the new and future Bell</span>
            </h3>
            <span className="white">
              Read the Bell case study<br />
              <span className="arrow-decorator white-arrow" />
            </span>

            <img className="PortfolioCard__bg" src="/portfolio/bell/bell-helicopter-card-bg.jpg" alt="Bell Helicopter Huey" />
          </Link>

          <Link
            to="/portfolio/neue-build/"
            style={{backgroundColor: `#00BAFE`}}
            className="PortfolioCard"
          >
            <img className="PortfolioCard__logo" src="/client-logos/neue-build-logo.svg" alt="Neue Build logo" />
            <h3 className="t-subheadline">
              NeueBuild&mdash;<span className="o-40">new build, new home, move in&nbsp;ready</span>
            </h3>
            <span className="">
              Read the Neue Build case study<br />
              <span className="arrow-decorator white-arrow" />
            </span>

            <img className="PortfolioCard__bg" src="/portfolio/neue-build/neue-build-card-bg.jpg" alt="Google map with various home indicators" />
          </Link>

        </div>
        <div className="w-50-l mt5-l">

          <Link
            to="/portfolio/fluidity-tech/"
            style={{backgroundColor: `#0761C1`}}
            className="PortfolioCard"
          >
            <img className="PortfolioCard__logo" src="/client-logos/fluidity-tech-icon.svg" alt="Fluidity Tech logo" />
            <h3 className="t-subheadline white">
              Fluidity Tech&mdash;<span className="o-40">revolutionizing drone control</span>
            </h3>
            <span className="white">
              Read the Fluidity Tech case study<br />
              <span className="arrow-decorator white-arrow" />
            </span>

            <img className="PortfolioCard__bg" src="/portfolio/fluidity-tech/fluidity-tech-card-bg.jpg" alt="Public safety worker with a drone" />
          </Link>

          <PortfolioCard bgColor={`#232825`} urlLink={`http://www.watermark.org/`}>
            <img className="PortfolioCard__logo" src="/client-logos/watermark-community-church-icon.svg" alt="Watermark Community Church logo" />
            <h3 className="t-subheadline white">
              Watermark&mdash;<span className="o-40">one church, four campuses, thousands of&nbsp;locations</span>
            </h3>
            <span className="white">
              Visit Watermark.org<br />
              <span className="arrow-decorator white-arrow" />
            </span>

            <img className="PortfolioCard__bg" src="/portfolio/watermark/watermark-card-bg.jpg" alt="Musicians on stage at Watermark" />
          </PortfolioCard>

        </div>
      </div>

      <section className="pv1 cf bg-deep-purple white">
        <div className="row">
          <div className="mv5">
            <ClientList />
          </div>
          <hr className="bt-1 bb-0 br-0 bl-0 b--white-10" />
          <div className="mv6 tc">
            <h2 className="f2 fw5 dark-red">Can we work with you?</h2>
            <p className="white-80 measure center">We&rsquo;ve worked with some really cool clients and solved big and small problems. We continually improve how we work to ensure we are top-notch and ready for any&nbsp;challenge.</p>
            <p className="white-80 sans-serif measure center">What can we work on together?</p>
            <Link
              to="/contact/"
              className="f6 fw5 link br1 ph3 pv3 mt4 dib bg-white black dim">
                Let&rsquo;s talk about your project →
            </Link>
          </div>
        </div>
      </section>

    </MainWrapper>
  </Layout>
)

export default PortfolioPage
